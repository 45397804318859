<template>
  <div class="products" id="welcome">
    <section class="products__welcome">
      <div class="products__welcome-container content-container">
        <h1>{{ $t('pages.products.welcome.title') }}</h1>

        <p v-html="$t('pages.products.welcome.paragraph')"></p>

        <div class="products__welcome-image">
          <ImageApp
              :path="'products__welcome.png'"
              :alt="$t('pages.products.welcome.title')"
          />
        </div>
      </div>
    </section>

    <section class="products__range">
      <div class="products__range-container content-container">
        <h2>{{ $t('pages.products.range.title') }}</h2>

        <div class="products__range-list">
          <div class="products__range-item">
            <h3>{{ $t('chicken') }}</h3>

            <div class="products__range-image">
              <ImageApp
                  :path="'products__range-chicken.png'"
                  :alt="$t('chicken')"
              />
            </div>

            <ol>
              <li v-for="item of $t('pages.products.range.chicken')" v-bind:key="item.index">{{ item }}</li>
            </ol>
          </div>

          <div class="products__range-item">
            <h3>{{ $t('pork') }}</h3>

            <div class="products__range-image">
              <ImageApp
                  :path="'products__range-pork.png'"
                  :alt="$t('pork')"
              />
            </div>

            <ol>
              <li>
                {{ $t('pages.products.range.pork.pork-bones.pork-bones') }}
                <ul>
                  <li>{{ $t('pages.products.range.pork.pork-bones.pork-shoulder') }}</li>
                  <li>{{ $t('pages.products.range.pork.pork-bones.hams') }}</li>
                  <li>{{ $t('pages.products.range.pork.pork-bones.belly-bones') }}</li>
                  <li>{{ $t('pages.products.range.pork.pork-bones.neck-bones') }}</li>
                  <li>{{ $t('pages.products.range.pork.pork-bones.loin-bones') }}</li>
                  <li>{{ $t('pages.products.range.pork.pork-bones.tail-bones') }}</li>
                </ul>
              </li>
              <li>{{ $t('pages.products.range.pork.feet') }}</li>
              <li>{{ $t('pages.products.range.pork.tails') }}</li>
              <li>{{ $t('pages.products.range.pork.half-heads') }}</li>
              <li>{{ $t('pages.products.range.pork.pork-ears') }}</li>
              <li>{{ $t('pages.products.range.pork.snouts') }}</li>
              <li>{{ $t('pages.products.range.pork.masks') }}</li>
              <li>{{ $t('pages.products.range.pork.stomachs') }}</li>
              <li>{{ $t('pages.products.range.pork.back-fat') }}</li>
              <li>{{ $t('pages.products.range.pork.cutting-fat') }}</li>
              <li>{{ $t('pages.products.range.pork.soft-fat') }}</li>
              <li>{{ $t('pages.products.range.pork.rind-skin') }}</li>
              <li>{{ $t('pages.products.range.pork.pork-sides') }}</li>
              <li>
                {{ $t('pages.products.range.pork.ribs.ribs') }}
                <ul>
                  <li>{{ $t('pages.products.range.pork.ribs.loin') }}</li>
                  <li>{{ $t('pages.products.range.pork.ribs.ribs-driblets') }}</li>
                </ul>
              </li>
              <li>{{ $t('pages.products.range.pork.liver') }}</li>
              <li>{{ $t('pages.products.range.pork.kidneys') }}</li>
              <li>{{ $t('pages.products.range.pork.hearts') }}</li>
              <li>{{ $t('pages.products.range.pork.pork-bellie') }}</li>
            </ol>
          </div>

          <div class="products__range-item">
            <h3>{{ $t('beef') }}</h3>

            <div class="products__range-image">
              <ImageApp
                  :path="'products__range-beef.png'"
                  :alt="$t('beef')"
              />
            </div>

            <ol>
              <li v-for="item of $t('pages.products.range.beef')" v-bind:key="item.index">{{ item }}</li>
            </ol>
          </div>

          <div class="products__range-item">
            <h3>{{ $t('pages.products.range.poultry-title') }}</h3>

            <div class="products__range-image">
              <ImageApp
                  :path="'products__range-poultry.png'"
                  :alt="$t('pages.products.range.poultry-title')"
              />
            </div>

            <ol>
              <li v-for="item of $t('pages.products.range.poultry')" v-bind:key="item.index">{{ item }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ImageApp from "@/components/ImageApp";

export default {
  name: "Products",
  components: {ImageApp},
}
</script>

<style lang="scss">
.products {
  padding-top: 220px;
  background: url("../assets/image/header.png") no-repeat top center;
  background-size: contain;

  &__welcome {
    padding: 0 0 50px 0;
    position: relative;
    background: transparent url("../assets/image/background__products_meat.png") no-repeat 0 35px;
    background-size: contain;
    overflow: hidden;

    @media (min-width: 768px) {
      padding: 0 0 80px 0;
    }

    @media (min-width: 1024px) {
      min-height: 830px;
      padding: 0;
    }

    &-container {
      position: relative;
      display: flex;
      flex-direction: column;

      @media (min-width: 550px) {

      }

      @media (min-width: 768px) {

      }
    }

    & h1 {
      font: 700 50px "Lato-Bold", sans-serif;
      color: #ff2e2b;
      padding: 15px 0 20px;
      position: relative;
      z-index: 1;

      @media (min-width: 550px) {
        font: 700 60px "Lato-Bold", sans-serif;
      }

      @media (min-width: 768px) {
        font: 700 70px "Lato-Bold", sans-serif;
        padding: 50px 0 20px;
      }

      @media (min-width: 1024px) {
        font: 700 80px "Lato-Bold", sans-serif;
        padding: 78px 0 27px;
      }
    }

    & p {
      max-width: 488px;
      font: 400 16px "Lato-Regular", sans-serif;
      color: #000;
      position: relative;
      z-index: 1;

      @media (min-width: 550px) {
        font: 400 18px "Lato-Regular", sans-serif;
      }

      @media (min-width: 768px) {
        font: 400 20px "Lato-Regular", sans-serif;
      }
    }

    &-image {
      display: none;

      @media (min-width: 768px) {
        display: block;
        position: absolute;
        right: -90px;
        top: 135px;
        max-width: 500px;
        width: 100%;
        transform: rotate(-35deg);
      }

      @media (min-width: 1024px) {
        right: -180px;
        top: 135px;
        max-width: 1070px;
        transform: rotate(-8deg);
      }

      @media (min-width: 1200px) {

      }
    }
  }

  &__range {
    min-height: 980px;
    background: url("../assets/image/background__range.png") no-repeat center;
    background-size: cover;
    padding: 80px 0 30px;

    &-container {
      margin-top: -80px;

      @media (min-width: 768px) {
        margin-top: -100px;
      }
    }

    & h2 {
      font: 700 50px "Lato-Bold", sans-serif;
      color: #ff2e2b;
      text-align: right;
      margin-bottom: 60px;

      @media (min-width: 550px) {
        font: 700 60px "Lato-Bold", sans-serif;
      }

      @media (min-width: 768px) {
        font: 700 70px "Lato-Bold", sans-serif;
      }

      @media (min-width: 1024px) {
        font: 700 80px "Lato-Bold", sans-serif;
      }
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 50px;

      @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;
      }

      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px;
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 1200px) {
        grid-gap: 108px;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 550px) {

      }

      & h3 {
        font: 700 30px "Lato-Bold", sans-serif;
        color: #000;
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
      }

      & ol {
        max-width: 205px;
        width: 100%;
        margin-top: 13px;
        font: 400 16px "Lato-Regular", sans-serif;
        color: #000;
        counter-reset: section;

        & > li {

          &::before {
            counter-increment: section;
            content: counters(section, ".") ". ";
          }

          & ul {
            margin-top: 0;
          }
        }
      }
    }

    &-image {
      max-width: 205px;
      width: 100%;
      border-radius: 10px;
    }
  }
}
</style>